import React, { ReactElement } from 'react';
import moment from 'moment';
import { HorarioFuncionamento } from '../../../types/HorarioFuncionamento';
import {
  DOMINGO,
  SEGUNDA,
  TERCA,
  QUARTA,
  QUINTA,
  SEXTA,
  SABADO,
} from '../../../utils/constantes';

interface Props {
  horariosFuncionamento: HorarioFuncionamento[];
}

const getDiaDaSemana = (diaSemanaId: number) => {
  switch (diaSemanaId) {
    case SEGUNDA:
      return 'Segunda';
    case TERCA:
      return 'Terça';
    case QUARTA:
      return 'Quarta';
    case QUINTA:
      return 'Quinta';
    case SEXTA:
      return 'Sexta';
    case SABADO:
      return 'Sábado';
    case DOMINGO:
      return 'Domingo';
    default:
      return 'Informações não encontradas, verifique sua conexão.';
  }
};

const HorariosModal = ({
  horariosFuncionamento,
}: Props): ReactElement | null => {
  if (!horariosFuncionamento.length) {
    return null;
  }

  const horarios = horariosFuncionamento
    .filter(
      (thing, index, self) =>
        index === self.findIndex(t => t.diaSemanaId === thing.diaSemanaId),
    )
    .sort((a, b) => a.diaSemanaId - b.diaSemanaId);

  return (
    <>
      {horarios.map(horario => (
        <div
          key={horario.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <span style={{ fontWeight: 'bold', color: 'rgb(95 97 100)' }}>
            {getDiaDaSemana(horario.diaSemanaId)}
          </span>
          <span style={{ color: '#868e96' }}>
            {moment(`2018 ${horario.horaInicio}`).format('HH:mm')} às{' '}
            {moment(`2018 ${horario.horaFim}`).format('HH:mm')}
          </span>
        </div>
      ))}
    </>
  );
};

export default HorariosModal;
