const getUri = (): string => {
  const identifier = window.location.pathname.match(/\w+/);

  if (identifier) {
    return identifier[0];
  }

  return '';
};

export default getUri;
