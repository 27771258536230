import React, { ReactElement } from 'react';

interface Props {
  title: string;
  show: boolean;
}

const AlertInput = ({ title, show }: Props): ReactElement => (
  <span
    className={`fade alert alert-danger ${show ? 'show' : ''}`}
    style={{ color: 'rgb(244, 81, 108)' }}
  >
    {title}
  </span>
);
export default AlertInput;
