import { createStore } from 'redux';
import throttle from 'lodash/throttle';
import { loadState } from './localStorage';
import persistState from './persistState';
import { StateRedux, Action } from './typeRedux';

const INITIAL_STATE = {
  tokenEmpresa: null,
  tokenUser: null,
  user: null,
  empresa: null,
  formasPagamento: [],
  endereco: null,
  pedidosId: null,
  pedidosProdutos: [],
};

const userRedux = (
  state: StateRedux = INITIAL_STATE,
  action: Action,
): StateRedux => {
  switch (action.type) {
    case 'TOKEN_EMPRESA':
      return {
        ...state,
        tokenEmpresa: action.tokenEmpresa,
      };

    case 'TOKEN_USER':
      return {
        ...state,
        tokenUser: action.tokenUser,
      };

    case 'USER':
      return {
        ...state,
        user: action.user,
      };

    case 'EMPRESA':
      return {
        ...state,
        empresa: action.empresa,
      };

    case 'FORMAS_PAGAMENTO':
      return {
        ...state,
        formasPagamento: action.formasPagamento,
      };

    case 'ENDERECO_EMPRESA':
      return {
        ...state,
        endereco: action.endereco,
      };

    case 'PEDIDO_ATIVO':
      return {
        ...state,
        pedidosId: action.pedidosId,
      };

    case 'ITENS_NO_CARRINHO':
      return {
        ...state,
        pedidosProdutos: action.pedidosProdutos
          ? [...state.pedidosProdutos, action.pedidosProdutos]
          : [],
      };

    default:
      return state;
  }
};

const persistedState = loadState();

const store = persistedState
  ? createStore(userRedux, persistedState)
  : createStore(userRedux);

store.subscribe(throttle(persistState(store), 1000));

export default store;
