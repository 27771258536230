import { Store } from 'redux';
import { saveState } from './localStorage';
import { StateRedux, Action } from './typeRedux';

export type AppStore = Store<StateRedux, Action>;

const persistState = (store: AppStore) => (): void =>
  saveState(store.getState());

export default persistState;
