import { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { StateRedux } from '../store/typeRedux';

const Header = (): ReactElement => {
  const { tokenUser } = useSelector((state: StateRedux) => state);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({
      type: 'TOKEN_USER',
      tokenUser: null,
    });

    dispatch({
      type: 'ITENS_NO_CARRINHO',
      pedidosProdutos: null,
    });

    dispatch({
      type: 'PEDIDO_ATIVO',
      pedidosId: null,
    });

    dispatch({
      type: 'USER',
      user: null,
    });
  };

  return (
    <header className="header_in clearfix">
      <div className="container">
        <div id="logo">
          <Link to="#plom">
            <img
              src="http://app.plom.com.br/theme/dist/html/default/assets/app/media/img/logos/logo-plom-165x62.png"
              height="35"
              alt=""
            />
          </Link>
        </div>
        <div className="layer" />
        <ul id="top_menu">
          <li>
            {tokenUser ? (
              <Link
                to="#logout"
                onClick={logout}
                style={{
                  color: '#f4516c',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  position: 'relative',
                  top: '10px',
                  float: 'right',
                  transition: 'all 0.3s ease-in-out',
                }}
              >
                Sair
              </Link>
            ) : (
              <Link
                to="#login"
                className="login"
                id="login-modal"
                data-toggle="modal"
                data-target="#modal-login"
              >
                Entrar
              </Link>
            )}
          </li>
        </ul>
        <nav className="main-menu">
          <div id="header_menu">
            <Link to="#1menu" className="open_close">
              <i className="icon_close" />
              <span>Menu</span>
            </Link>
            <Link to="#menu">
              <img src="img/logo.svg" width="162" height="35" alt="" />
            </Link>
          </div>
          <ul>
            {/* <li className="submenu">
              <Link to="#inicio" className="show-submenu">
                Início
              </Link>
            </li> */}
            <li className="submenu">
              <a href="#section-cardapio" className="show-submenu">
                Cardápio
              </a>
            </li>
            {/* <li className="submenu">
              <Link to="#contato" className="show-submenu">
                Contato
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
