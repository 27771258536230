export const URL = 'https://api.plom.com.br/';

export const CONDICAO_VOUCHER = {
  USO_UNICO: 'uso_unico',
  PRIMERIA_COMPRA: 'primeira_compra',
};

export const DOMINGO = 1;
export const SEGUNDA = 2;
export const TERCA = 3;
export const QUARTA = 4;
export const QUINTA = 5;
export const SEXTA = 6;
export const SABADO = 7;
export const OUTROS = 8;
