/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { StateRedux } from './typeRedux';
import getUri from '../utils/get-uri';

export const loadState = (): any => {
  const serializedState = localStorage.getItem(getUri());
  if (!serializedState) {
    return null;
  }

  try {
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

export const saveState = (state: StateRedux): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(getUri(), serializedState);
  } catch (err) {
    console.error(err);
  }
};

export const deleteState = (): void => {
  localStorage.removeItem(getUri());
};
