import { ReactElement } from 'react';
import Lottie, { Options } from 'react-lottie';
import loading from '../lottie/loading.json';

interface Props {
  show: boolean;
}

const Loading = ({ show }: Props): ReactElement => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000000,
          width: '100vw',
          height: '100vh',
          backgroundColor: '#000',
          opacity: 0.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </div>
  );
};

export default Loading;
