import React, { ReactElement } from 'react';
import range from 'lodash/range';
import Skeleton from 'react-loading-skeleton';

const LoadSkeletonInicial = (): ReactElement => (
  <div style={{ margin: '20px 40px' }}>
    <Skeleton height={300} style={{ marginBottom: 60 }} />
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {range(0, 5).map(index => (
          <div key={index} style={{ display: 'flex' }}>
            <Skeleton width={490} height={90} style={{ marginBottom: 20 }} />
            <span style={{ margin: 15 }} />
            <Skeleton width={490} height={90} style={{ marginBottom: 20 }} />
          </div>
        ))}
      </div>
      <Skeleton height={530} width={400} />
    </div>
  </div>
);

export default LoadSkeletonInicial;
