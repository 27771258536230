import React, { ReactElement, ReactNode, CSSProperties } from 'react';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const FormGroup = ({ children, style }: Props): ReactElement => (
  <div className="form-group" style={style}>
    {children}
  </div>
);

FormGroup.defaultProps = {
  style: undefined,
};

export default FormGroup;
