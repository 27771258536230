import { AxiosPromise } from 'axios';
import xhrEmpresa from './xhrEmpresa';
import xhr from './xhr';
import { Empresa, ContatoEmpresa } from '../types/Empresa';
import { Categoria, ParamsCategorias } from '../types/Categoria';
import { Endereco, EnderecoCadastro, EnderecoPorCep } from '../types/Endereco';
import { FormaPagamento } from '../types/Pagamento';
import { Token } from '../types';
import { Login } from '../types/Login';
import { Usuario, UsuarioCadastro } from '../types/Usuario';
import { HorarioFuncionamento } from '../types/HorarioFuncionamento';
import { Carrinho } from '../types/Carrinho';
import { ParamsFinalizarPedido, PedidoParaCarrinho } from '../types/Pedido';
import { Voucher } from '../types/Voucher';
import { Contato, ContatoCadastro } from '../types/Contato';
import { Produto } from '../types/Produto';
import { AvaliacoesResponse } from '../types/Avaliacoes';

const getUsuario = (): AxiosPromise<Usuario[]> => xhr.get('/usuario');

const loginUsuario = ({
  empresasId,
  email,
  senha,
  nivel,
}: Login): AxiosPromise<Token> =>
  xhrEmpresa.post('/autenticacao', { empresasId, email, senha, nivel });

const loginParaEmpresa = (empresasId: number): AxiosPromise<Token> =>
  xhrEmpresa.post('/autenticacao', { empresasId, nivel: 'C' });

const adicionarUsuario = (usuario: UsuarioCadastro): AxiosPromise =>
  xhrEmpresa.post('/usuarios', usuario);

const redefinirSenha = (email: string, empresasId: number): AxiosPromise =>
  xhrEmpresa.post('/usuario/esqueci-minha-senha-cliente', {
    email,
    empresasId,
  });

const verificarCodigoAlterarSenha = (
  email: string,
  codigo: string,
): AxiosPromise =>
  xhrEmpresa.get(`/usuario/esqueci-minha-senha-cliente/${codigo}`, {
    params: { email },
  });

interface AlterarSenha {
  email: string;
  senha: string;
  empresasId: number | undefined;
}

const alterarSenha = (params: AlterarSenha, codigo: string): AxiosPromise =>
  xhrEmpresa.put(`/usuario/esqueci-minha-senha-cliente/${codigo}`, params);

const adicionarContato = (params: ContatoCadastro): AxiosPromise =>
  xhr.post('/contatos', params);

const buscarPedidoDoUsuario = (usuarioId: number): AxiosPromise =>
  xhr.get(`usuario/${usuarioId}/pedido`);

const getEstabelecimentoPorIdentificador = (
  identifier: string,
): AxiosPromise<Empresa> => {
  const params = { identificador: identifier };
  return xhrEmpresa.get('/empresas/packages', { params });
};

const getProdutosPorCategoria = (
  params: ParamsCategorias,
): AxiosPromise<Categoria[]> => xhrEmpresa.get('/v2/categorias', { params });

const getProdutosPorGrupoDeComplementos = (params: {
  id: number;
  quantidade: number;
}): AxiosPromise =>
  xhrEmpresa.get(`/grupos-complemento/${params.id}/produtos`, { params });

const getEnderecoDoEstabeleciomento = (): AxiosPromise<Endereco> =>
  xhrEmpresa.get('/empresa/enderecos');

const getAvaliacoes = (): AxiosPromise<AvaliacoesResponse> =>
  xhrEmpresa.get('/avaliacoes');

const getEnderecoEmpresa = (empresasId: number): AxiosPromise<Endereco> =>
  xhrEmpresa.get('/empresas/endereco', { params: { empresasId } });

const getEnderecoPorCep = (cep: string): AxiosPromise<EnderecoPorCep> =>
  xhr.get('/busca-cep', { params: { cep } });

const getContatoEmpresa = (
  empresaId: number,
): AxiosPromise<ContatoEmpresa[]> => {
  const params = { empresasId: empresaId };
  return xhrEmpresa.get('/empresas/contatos', { params });
};

const buscarFormasPagamento = (): AxiosPromise<FormaPagamento[]> =>
  xhrEmpresa.get('/empresa/formas-pagamento');

const getHorariosFuncionamento = (): AxiosPromise<HorarioFuncionamento[]> =>
  xhrEmpresa.get('/funcionamento', {
    params: { ordenacao: 'dia_semana_id asc' },
  });

const criarPedido = (): AxiosPromise => xhr.post('/pedidos');

const adicionarAoCarrinho = (
  pedidosId: number,
  produto: PedidoParaCarrinho,
): AxiosPromise => xhr.post(`/pedidos/${pedidosId}/produtos`, produto);

const enviarPedido = (
  pedidosId: number,
  params: ParamsFinalizarPedido,
): AxiosPromise => xhr.put(`/pedidos/${pedidosId}`, params);

const buscarProdutosDoCarrinho = (pedidoId: number): AxiosPromise<Carrinho> =>
  xhr.get(`/pedidos/${pedidoId}`);

const updateProdutoCarrinho = (
  pedidoId: number,
  produto: Produto | { id: number; quantidade: number },
): AxiosPromise =>
  xhr.put(`/pedidos/${pedidoId}/produtos/${produto.id}`, produto);

const deleteProdutoCarrinho = (
  pedidoId: number,
  produto: Produto | { id: number },
): AxiosPromise => xhr.delete(`/pedidos/${pedidoId}/produtos/${produto.id}`);

const buscarContatosUsuario = (): AxiosPromise<Contato[]> =>
  xhr.get('/usuario/contatos');

const cadastrarEnderecoUsuario = (
  enderecos: EnderecoCadastro,
): AxiosPromise<{ id: number }> => xhr.post('/enderecos', enderecos);

const buscarEnderecosDeEntrega = (): AxiosPromise<Endereco[]> =>
  xhr.get('/usuario/enderecos');

const buscarVouchers = (usuariosId: number): AxiosPromise<Voucher[]> =>
  xhr.get(`/usuarios/${usuariosId}/vouchers`);

const buscarVoucherPrivado = (
  id: number,
  voucher: string,
): AxiosPromise<Voucher> => xhr.get(`/pedidos/${id}/vouchers/${voucher}`);

const api = {
  loginUsuario,
  loginParaEmpresa,
  adicionarUsuario,
  redefinirSenha,
  verificarCodigoAlterarSenha,
  adicionarContato,
  alterarSenha,
  buscarPedidoDoUsuario,
  getEstabelecimentoPorIdentificador,
  getProdutosPorCategoria,
  getProdutosPorGrupoDeComplementos,
  getEnderecoDoEstabeleciomento,
  getAvaliacoes,
  getEnderecoEmpresa,
  getEnderecoPorCep,
  getContatoEmpresa,
  buscarFormasPagamento,
  getHorariosFuncionamento,
  getUsuario,
  criarPedido,
  adicionarAoCarrinho,
  enviarPedido,
  buscarProdutosDoCarrinho,
  updateProdutoCarrinho,
  deleteProdutoCarrinho,
  buscarContatosUsuario,
  cadastrarEnderecoUsuario,
  buscarEnderecosDeEntrega,
  buscarVouchers,
  buscarVoucherPrivado,
};

export default api;
