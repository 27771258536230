import { ReactElement, CSSProperties } from 'react';
import getElementoPorId from '../utils/getElementoPorId';

interface Props {
  modalId: string;
  title: string;
  children: ReactElement;
  onDismiss?: () => void;
  style?: CSSProperties;
}

export const closeModal = (modalId: string): void =>
  getElementoPorId(`modal-fechar-${modalId}`)?.click();

const ModalLogin = ({
  title,
  children,
  modalId,
  onDismiss,
  style,
}: Props): ReactElement => (
  <div
    className="modal-custom modal fade"
    data-toggle="modal"
    id={modalId}
    role="dialog"
    aria-hidden="true"
    style={style}
  >
    <div className="modal-dialog" />
    <div className="mfp-container mfp-inline-holder">
      <div className="mfp-content">
        <div id="modal-dialog" className="zoom-anim-dialog">
          <div
            id="sign-in-dialog"
            style={{ padding: 0, margin: 0, maxWidth: '100%' }}
          >
            <div className="small-dialog-header" style={{ margin: 0 }}>
              <h3 style={{ textAlign: 'center' }}>{title}</h3>
              <button
                type="button"
                className="mfp-close close-modal"
                id={`modal-fechar-${modalId}`}
                data-dismiss="modal"
                aria-label="Close"
                onClick={onDismiss}
              >
                <span />
              </button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

ModalLogin.defaultProps = {
  onDismiss: () => undefined,
  style: undefined,
};

export default ModalLogin;
