/* eslint-disable no-param-reassign */

import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { URL } from './constantes';
import getUri from './get-uri';

interface Header {
  'User-Device': string;
  'Content-Type': string;
  Authorization?: string;
}

const getToken = async (): Promise<Header> => {
  const item: string | null = localStorage.getItem(getUri());

  if (!item) {
    return new Promise(resolve => {
      resolve({
        'User-Device': 'mobile',
        'Content-Type': 'application/json',
      });
    });
  }

  const { tokenEmpresa } = JSON.parse(item);

  return new Promise(resolve => {
    resolve({
      'User-Device': 'mobile',
      'Content-Type': 'application/json',
      Authorization: tokenEmpresa,
    });
  });
};

const xhrEmpresa = axios.create({
  baseURL: URL,
});

xhrEmpresa.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.paramsSerializer = qs.stringify;
    config.headers = await getToken();
    return config;
  },
  error => Promise.reject(error),
);

export default xhrEmpresa;
