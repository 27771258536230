import { ReactElement, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import Anchor from '../../Components/Anchor';
import FormGroup from '../../Components/FormGroup';
import AlertInput from '../../Components/AlertInput';
import Icon from '../../Components/Icon';
import Loading from '../../Components/Loading';
import validarEmail from '../../utils/validarEmail';
import { StateRedux } from '../../store/typeRedux';
import { closeModal } from '../../Components/Modal';
import { exibirToastError } from '../../utils/alertas';
import api from '../../utils/api';

const Login = (): ReactElement => {
  const [email, setEmail] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [mostrarSenha, setMostrarSenha] = useState<boolean>(false);

  const { empresa } = useSelector((state: StateRedux) => state);
  const dispatch = useDispatch();

  const isEmailValid = (): boolean =>
    !email || (!!email.length && !!validarEmail(email));

  const formularioValido = (): boolean => {
    if (!email) {
      exibirToastError('E-mail é obrigatório!');
      return false;
    }

    if (!validarEmail(email)) {
      exibirToastError('E-mail inválido!');
      return false;
    }

    if (!senha) {
      exibirToastError('Senha é obrigatória!');
      return false;
    }

    return true;
  };

  const extrairDadosTokenUsuario = async (tokenUser: string) => {
    interface Decode {
      data: {
        empresa: number;
        usuariosId: number;
        nivel: string;
      };
    }

    const decode: Decode = jwtDecode(tokenUser);

    if (!decode) {
      exibirToastError('Por favor faça login novamente');
      return;
    }

    const {
      data: { usuariosId, nivel },
    } = decode;

    dispatch({
      type: 'TOKEN_USER',
      tokenUser,
    });

    dispatch({
      type: 'USER',
      user: {
        usuariosId,
        nivel,
      },
    });

    const {
      data: { id: pedidosId },
    } = await api.buscarPedidoDoUsuario(usuariosId);

    dispatch({
      type: 'PEDIDO_ATIVO',
      pedidosId,
    });
  };

  const handleSignin = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const opcoes = {
      empresasId: empresa?.id || 0,
      email,
      senha,
      nivel: 'C',
    };

    if (!formularioValido()) {
      return;
    }

    try {
      setLoading(true);
      const {
        data: { token: tokenUser },
      } = await api.loginUsuario(opcoes);

      extrairDadosTokenUsuario(tokenUser);

      closeModal('modal-login');
    } catch (error) {
      exibirToastError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="content"
      style={{ backgroundColor: '#FFFFFF', padding: 20 }}
    >
      <Loading show={loading} />
      <form style={{ height: 'fit-content' }} onSubmit={handleSignin}>
        <div className="sign-in-wrapper">
          <FormGroup>
            <span>E-mail</span>
            <input
              type="email"
              className="form-control"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <i className="icon_mail_alt" />
            <AlertInput title="E-mail inválido" show={!isEmailValid()} />
          </FormGroup>
          <FormGroup>
            <span>Senha</span>
            <div
              className="hideShowPassword-wrapper"
              style={{
                position: 'relative',
                display: 'block',
                verticalAlign: 'baseline',
                margin: 0,
              }}
            >
              <input
                type={mostrarSenha ? 'text' : 'password'}
                className="form-control hideShowPassword-field"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSenha(e.target.value)
                }
                style={{ margin: 0, paddingRight: 0 }}
              />
              <button
                type="button"
                aria-label="Show Password"
                title="Mostrar senha"
                className="my-toggle"
                aria-pressed="false"
                onClick={() => setMostrarSenha(!mostrarSenha)}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  marginTop: -15,
                }}
              >
                {mostrarSenha ? 'Esconder' : 'Mostrar'}
              </button>
            </div>
            <Icon
              className={mostrarSenha ? 'icon_lock-open_alt' : 'icon_lock_alt'}
            />
          </FormGroup>
          <div className="clearfix add_bottom_15">
            {/* <div className="checkboxes float-left">
              <span className="container_check">
                Lembre de mim
                <input type="checkbox" />
                <span className="checkmark" />
              </span>
            </div> */}
            <div className="float-right">
              <Anchor
                data-toggle="modal"
                data-target="#modal-esqueci-senha"
                onClick={() => closeModal('modal-login')}
              >
                Esqueci minha senha
              </Anchor>
            </div>
          </div>
          <div className="text-center">
            <input
              type="submit"
              value="Entrar"
              className="btn_1 full-width mb_5"
            />
            Não possui uma conta?{' '}
            <Anchor
              data-toggle="modal"
              data-target="#modal-cadastro"
              onClick={() => closeModal('modal-login')}
            >
              Inscrever-se
            </Anchor>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
