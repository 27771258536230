import { SweetAlertResult } from 'sweetalert2';
import get from 'lodash/get';
import swal, { toastError, toastSuccess } from './swal';

const alertas = {
  exibirError: (title: string, text: string): Promise<SweetAlertResult> =>
    swal.fire({
      icon: 'error',
      title,
      text,
    }),
};

export const showErrorForDev = (message: string): void =>
  console.error(message); // eslint-disable-line no-console

export const exibirToastSucesso = (mensagem: string, timer?: number): void =>
  toastSuccess(timer).fire(mensagem);

export const exibirToastError = (mensagem: string, timer?: number): void =>
  toastError(timer).fire(mensagem);

export const exibirToastErrorCatch = (error: any, timer?: number): void => {
  const msgError = 'Algo deu errado tente novamente mais tarde';
  const msgErrEndereco = 'Serviço não é atendido nesta região';

  if (error.response.status === 500) {
    toastError(timer).fire(
      error.response.data.includes(msgErrEndereco) ? msgErrEndereco : msgError,
    );
    return;
  }

  toastError(timer).fire(get(error, 'response.data.message', msgError));
};

export default alertas;
