/* eslint-disable @typescript-eslint/no-explicit-any */
import SweetAlerts from 'sweetalert2';

const BRAND_COLOR = '#af5bf1';

const swal = SweetAlerts.mixin({
  confirmButtonColor: BRAND_COLOR,
  cancelButtonText: 'Cancelar',
  reverseButtons: true,
});

export const toast = swal.mixin({
  toast: true,
  timer: 4000,
  showConfirmButton: false,
  position: 'bottom',
});

const success = (timer = 5000) =>
  swal.mixin({
    icon: 'success',
    toast: true,
    timer,
    showConfirmButton: false,
    position: 'bottom',
  });

const error = (timer = 5000) =>
  swal.mixin({
    icon: 'error',
    toast: true,
    timer,
    showConfirmButton: false,
    position: 'bottom',
  });

export const toastSuccess = (timer?: number): any => success(timer);

export const toastError = (timer?: number): any => error(timer);

export default swal;
