import React, { ReactElement } from 'react';

interface Props {
  className: string;
}

const Icon = ({ className }: Props): ReactElement => (
  <i className={className} />
);
export default Icon;
