/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import { FC, AnchorHTMLAttributes, useState } from 'react';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const Anchor: FC<AnchorProps> = ({ children, ...rest }) => {
  const [url] = useState('');
  return (
    <a href={url} {...rest}>
      {children}
    </a>
  );
};

export default Anchor;
