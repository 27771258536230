import { ReactElement, useEffect, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { Empresa } from '../../types/Empresa';
import { Endereco } from '../../types/Endereco';
import { HorarioFuncionamento } from '../../types/HorarioFuncionamento';
import { Avaliacao } from '../../types/Avaliacoes';
import HorariosModal from './Components/HorariosModal';
import Modal from '../../Components/Modal';
import { StateRedux } from '../../store/typeRedux';

interface Props {
  empresa: Empresa | null;
  avaliacao: Avaliacao | null;
  horariosFuncionamento: HorarioFuncionamento[];
  filtrarProdutosDoCardapio: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Apresentacao = ({
  empresa,
  avaliacao,
  horariosFuncionamento,
  filtrarProdutosDoCardapio,
}: Props): ReactElement => {
  const { endereco: address } = useSelector((state: StateRedux) => state);
  const [endereco, setEndereco] = useState<Endereco | null>(null);

  const getUrlDaImagemCapa = (): string => {
    if (!empresa || !empresa.identificador) {
      return 'https://plom.com.br/img/capa-defaut-web-loja.jpeg';
    }

    const { id, identificador } = empresa;
    return `url(https://api.plom.com.br/imagens/${id}/capa-web/${identificador}.png)`;
  };

  useEffect(() => {
    setEndereco(address);
  }, [address]);

  return (
    <>
      <Modal
        title="Horários de funcionamento"
        modalId="m-horario-funcionamento"
      >
        <HorariosModal horariosFuncionamento={horariosFuncionamento} />
      </Modal>
      <div
        className="hero_in detail_page background-image"
        style={{
          backgroundImage: getUrlDaImagemCapa(),
        }}
      >
        <div
          className="wrapper opacity-mask"
          data-opacity-mask="rgba(0, 0, 0, 0.5)"
          style={{ backgroundColor: '#00000061' }}
        >
          <div className="container">
            <div className="main_info">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6">
                  {avaliacao && (
                    <div className="head">
                      <div className="score">
                        <span>
                          Nota
                          <em>
                            {avaliacao.totalAvaliacoes <= 1
                              ? `${avaliacao.totalAvaliacoes} Avaliação`
                              : `${avaliacao.totalAvaliacoes} Avaliações`}
                          </em>
                        </span>
                        <strong>{avaliacao.media}</strong>
                      </div>
                    </div>
                  )}
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div
                      style={{
                        backgroundColor: 'rgb(255 255 255 / 0%)',
                        padding: 15,
                        margin: 5,
                        borderRadius: 10,
                        alignSelf: 'center',
                      }}
                    >
                      <img
                        src={`https://api.plom.com.br/imagens/${empresa?.logo}`}
                        height="70"
                        alt="Logo"
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        padding: 15,
                        margin: 5,
                        borderRadius: 10,
                      }}
                    >
                      <h1>{empresa?.fantasia}</h1>
                    </div>
                  </div>
                  <span style={{ marginLeft: 6 }}>
                    {`${endereco?.logradouro},
                      ${endereco?.numero},
                      ${endereco?.bairro}, ${endereco?.cidade} - ${endereco?.estado}`}
                  </span>
                  {/* <Link
                    to="https://www.google.com.br/maps/place/R.+Su%C3%A9cia,+133+-+Parque+das+Na%C3%A7%C3%B5es,+Santo+Andr%C3%A9+-+SP,+09280-080/@-23.6360455,-46.519184,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce42a0b2dcb217:0xe3e47896b989aee9!8m2!3d-23.6360504!4d-46.5169953"
                    target="blank"
                  >
                    (Maps)
                  </Link> */}
                  <br />
                  <br />
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#m-horario-funcionamento"
                    style={{
                      backgroundColor: 'transparent',
                      color: '#ffffff',
                      border: 'transparent',
                    }}
                  >
                    <i className="icon_clock" /> Horários de funcionamento
                  </button>
                </div>
                <div className="col-xl-8 col-lg-7 col-md-6">
                  <div
                    className="buttons clearfix"
                    style={{ marginTop: 20, minWidth: '46%' }}
                  >
                    <div className="search_bar_list">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar"
                        onInput={filtrarProdutosDoCardapio}
                      />
                      <button type="submit" style={{ cursor: 'no-drop' }}>
                        <i className="icon_search" />
                      </button>
                    </div>
                    {/* <span className="magnific-gallery">
                      <Link
                        to="#0"
                        title="Photo title"
                        data-effect="mfp-zoom-in"
                      />
                      <Link
                        to="#0"
                        title="Photo title"
                        data-effect="mfp-zoom-in"
                      />
                    </span> */}
                    {/* <Link to="#gostei" className="btn_hero wishlist">
                      <i className="icon_heart" />
                      Gostei
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apresentacao;
